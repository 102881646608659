import React from 'react';
import { BsTelephoneFill } from 'react-icons/bs';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa6';

const SubHeader = () => {
  return (
    <>
      <div className='container-fluid subHeader_bg'>
        <div className='row p-1'>
          <div className='col-md-1'></div>
          <div className='col-md-9 text-white mt-1'>
            <span className='font_size_14px'>Have any questions?</span>
            <span className='font_size_14px' style={{ marginInline: '20px' }}>
              <BsTelephoneFill /> +1 609 450 3535
            </span>
            <span className='font_size_14px'>
              <b>@</b> Info.trialfinity@gmail.com
            </span>
          </div>
          <div className='col-md-2'>
            <span style={{ background: '#1B40CB', marginRight: '10px', padding: '0px 4px 4px 4px' }}>
              <a href='https://www.facebook.com/profile.php?id=100093549819204'>
                <FaFacebookF style={{ color: 'white' }} />
              </a>
            </span>
            <span style={{ background: '#217BE0', padding: '0px 4px 4px 4px', marginRight: '10px' }}>
              <a href='https://www.linkedin.com/in/trialfinity-clinical-research-444a1627a/'>
                <FaLinkedinIn style={{ color: 'white' }} />
              </a>
            </span>
            <span style={{ background: 'black', padding: '0px 4px 4px 4px', marginRight: '10px' }}>
              <a href='https://www.instagram.com/trialfinity'>
                <FaInstagram style={{ color: 'white' }} />
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubHeader;
