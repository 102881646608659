import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [toggleicon, setToggleicon] = useState(true);
  const [crossicon, setCrossicon] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const closeMenu = () => {
    setIsNavbarOpen(false);
    setCrossicon(false);
    setToggleicon(true);
  };

  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-light navbar_style'>
        <Link className='navbar-brand' to='/'>
          <img src='./images/trial_logo.png' style={{ width: '100%', maxWidth: '100%', height: '69px' }} />
        </Link>
        <button className='navbar-toggler btn_css_for_toggle_bar' type='button' onClick={handleToggle}>
          {toggleicon && (
            <img
              src='./images/toggle_icon_sidebar.png'
              className='toggle_icon2'
              width='250'
              alt='toggle_icon'
              onClick={() => {
                setCrossicon(true);
                setToggleicon(false);
              }}
            />
          )}
        </button>
        <div className={`collapse navbar-collapse my_style_for_navbar ${isNavbarOpen ? 'show' : ''}`}>
          <div className='navbar-nav p-1 '>
            <div className='d-flex log_and_btn_space'>
              <img
                src='./images/Trailfinity-logo1.png'
                style={{ width: '100%', maxWidth: '60%', height: '59px' }}
                className='mobile_second_logo'
              />
              <button
                className='navbar-toggler'
                type='button'
                onClick={handleToggle}
                style={{ border: 'none', backgroundColor: '#6ec1e4', borderRadius: '0px' }}
              >
                {crossicon && (
                  <img
                    src='./images/Close_icon.png'
                    className='toggle_icon3'
                    width='250'
                    alt='toggle_icon'
                    onClick={() => {
                      setToggleicon(true);
                      setCrossicon(false);
                    }}
                  />
                )}
              </button>
            </div>
            <Link className='nav-item nav-link active headerMenu' to='/' onClick={closeMenu}>
              Home
            </Link>
            <Link className='nav-item nav-link headerMenu' to='about-us' onClick={closeMenu}>
              About Us
            </Link>
            <Link className='nav-item nav-link headerMenu' to='therapeutic-areas' onClick={closeMenu}>
              Therapeutic Areas
            </Link>
            <Link className='nav-item nav-link headerMenu' to='outpatient-clinical-trial' onClick={closeMenu}>
              Outpatient Clinical trial
            </Link>
            <li className='nav-item dropdown headerMenu' style={{ marginLeft: '-0px' }}>
              <Link
                className='nav-link headerMenu'
                href='#'
                id='navbarDropdownMenuLink'
                role='button'
                data-bs-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Our Services +
              </Link>
              <div className='dropdown-menu' aria-labelledby='navbarDropdownMenuLink' style={{ marginTop: '-12px' }}>
                <Link className='dropdown-item' to='patients' style={{ color: '#0c7bc0' }} onClick={closeMenu}>
                  Patient
                </Link>
                <Link className='dropdown-item' to='sponsor' style={{ color: '#0c7bc0' }} onClick={closeMenu}>
                  Sponsor
                </Link>
                <Link className='dropdown-item' to='i-am-a-physician' style={{ color: '#0c7bc0' }} onClick={closeMenu}>
                  I am a Physician
                </Link>
              </div>
            </li>
            <Link
              className='nav-item nav-link headerMenu'
              to='contact-us'
              style={{ marginLeft: '22px', color: '#0c7bc0' }}
              onClick={closeMenu}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
