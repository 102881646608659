import React, { useEffect } from 'react';
export let Therapeutic_Areas = [
  {
    name: 'Oncology',
  },
  {
    name: 'Central Nervous System',
  },
  {
    name: 'Infectious Disease & Vaccines',
  },
  {
    name: 'Cardiovascular',
  },
  {
    name: 'Dermatology',
  },
  {
    name: 'GI & Hepatalogy',
  },
  {
    name: 'Endocrinology',
  },
  {
    name: 'Allergy & Respiratory',
  },
  {
    name: 'Rheumatology',
  },
  {
    name: 'Ophthalmology',
  },
  {
    name: 'Nephrology',
  },
  {
    name: 'Reproductive Health',
  },
  {
    name: 'Womens Health',
  },
  {
    name: 'Pain',
  },
  {
    name: 'Obesity',
  },
  {
    name: 'Tobacco Evaluation',
  },
];

const TherapeuticAreas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className='container pt-5'>
        <div className='row'>
          {Therapeutic_Areas.map((item, index) => (
            <div className='col-md-3 pt-3'>
              <button className='tAreas_Button'>{item.name}</button>
            </div>
          ))}
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default TherapeuticAreas;
