import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';

const SectionThree = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  return (
    <>
      <section className='ftco-section' data-aos='fade-up' data-aos-duration='2000'>
        <div className='container-fluid text-white our_culture1' style={{ background: '#0c7bc0' }}>
          <div className='row'>
            <div className='col-md-5 our_culture2'>
              <h1>Our Culture</h1>
              <h4 className='avancing'>Advancing Health. Advancing Lives.</h4>
              <p style={{ color: '#E3E3E3' }}>
                We strive to improve patient lives by addressing unmet medical needs through clinical research. We aim
                to make clinical research accessible, convenient, and easier for our trial participants.
              </p>
            </div>
            <div className='col-md-7 our_culture p-3'>
              <div className='row'>
                <div className='col-sm-3 text-center'>
                  <img src='./images/vision.png' className='img-fluid img_vision_width' />
                </div>
                <div className='col-sm-8'>
                  <h4 className='text-primary border-5 culture_icons_one'>Vision</h4>
                  <p className='text-black'>
                    We envision a world where evidence-based medicine and innovation intersect seamlessly resulting in
                    global health advancements. With a commitment to translating research into practice, we strive to
                    bring innovative medicine to our communities in large to improve patient health and quality of life.
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-3 text-center'>
                  <img src='./images/mission.png' className='img-fluid img_vision_width' />
                </div>
                <div className='col-sm-8'>
                  <h4 className='text-primary border-5 culture_icons_one'>Mission</h4>
                  <p className='text-black'>
                    We collaborate with Industry leaders and conduct research with novel medicines without comprising
                    timelines and quality of data. With our approach, we ensure clinical studies are executed with the
                    highest quality standard right from feasibility to study closure.
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-3 text-center'>
                  <img src='./images/motto.png' className='img-fluid img_vision_width' />
                </div>
                <div className='col-sm-8'>
                  <h4 className='text-primary border-5 culture_icons_one'>Motto</h4>
                  <p className='text-black'>
                    We create a culture that fosters excellence, drives innovation, and ultimately contributes to the
                    advancement of healthcare and the well-being of individuals and communities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionThree;
