import Aos from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';
import CountUp from 'react-countup';

const SectionTwo = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  return (
    <>
      <div className='container' data-aos='fade-up' data-aos-duration='2000'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='counter_bg p-3'>
              <div className='row'>
                <div className='col-md-4 text-center calc_margin_bottom'>
                  <CountUp end={18} duration={5.75} style={{ fontSize: '50px', fontWeight: 'bolder' }} />
                  <p className='section_two_sub'>Studies Completed</p>
                </div>
                <div className='col-md-4 text-center calc_margin_bottom'>
                  <CountUp end={25} duration={5.75} style={{ fontSize: '50px', fontWeight: 'bolder' }} />
                  <span style={{ fontSize: '50px', fontWeight: 'bolder' }}>+</span>
                  <p className='section_two_sub'>Years of Experience</p>
                </div>

                <div className='col-md-4 text-center calc_margin_bottom'>
                  <CountUp end={5000} duration={5.75} style={{ fontSize: '50px', fontWeight: 'bolder' }} />
                  <span style={{ fontSize: '50px', fontWeight: 'bolder' }}>+</span>
                  <p className='section_two_sub'>Access to Participants data base</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <img src='./images/sectionTwo.jpg' alt='' />
            </div>
          </div>
          <div className='col-md-6 section_two_cutting'>
            <h4 style={{ color: '#0c7bc0', fontSize: '34px', lineHeight: '49px', fontWeight: 'bolder' }}>
              Welcome to the Trialfinity Clinical Research Center!
            </h4>
            <p>
              Dedicated to Advancing Healthcare Through Cutting-Edge Research
              <br />
              <br /> We are driven by a common goal: To improve healthcare outcomes and shape the future of medicine
              through rigorous and innovative research. As a leading clinical research center, our top medical experts
              with a patient-centered approach drive transformative discoveries by conducting studies in compliance with
              ICH GCP, Ethics Committee and US FDA requirements.
              <br />
              <br /> Our research center is equipped with state-of-the-art infrastructure and advanced technology. Our
              research facilities provide an optimal environment for conducting comprehensive and robust studies. We
              maintain a rigorous adherence to ethical standards and regulatory guidelines, ensuring the safety and
              well-being of participants involved in our research.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default SectionTwo;
