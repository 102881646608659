import React, { useEffect } from 'react';
import { BsDot } from 'react-icons/bs';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <img src='./images/about_us.jpg' alt='' style={{ width: '100%' }} />
          </div>
          <div className='col-md-6 p-3'>
            Trialfinity is a multi-disciplinary clinical research site that brings many years of experience and
            leadership in the research community to the Hamilton, Trenton area. We bring together a diverse team of
            researchers, clinicians, scientists, and industry partners with expertise in various fields of healthcare
            and biomedical research. Our collective knowledge and experience in clinical trial allow us to tackle
            complex healthcare challenges from multiple perspectives, driving innovative solutions and groundbreaking
            discoveries.
            <br />
            <br /> Our clinical research expertise in conducting Phase I-IV clinical trial helps us to build trust and
            strong relationship with our community. And our commitment to quality and integrity of data is paramount
            which helps pharmaceutical and biotechnology to bring innovative medicine and enhance quality of patient
            lives.
          </div>
        </div>
      </div>
      <br />
      <div className='container'>
        <div className='row'>
          <h4 className='common_color'>Facilities</h4>
          <br />
          <br />
          <p>
            Trialfinity has a 2,500-square-foot dedicated research facility in Hamilton, New Jersey. Our building is
            conveniently located near major medical centers and is only 15 miles from the Trenton Airport.
          </p>
        </div>
        <div className='row'>
          <h4 className='common_color'>Facility Features</h4>
          <br />
          <br />
          <p>
            <BsDot style={{ fontSize: '30px' }} />
            Fully-alarmed, 24-hour monitored security system with selective pin access
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            Dedicated monitoring rooms with high-speed Internet, copier, phone and fax
            <br />
            <BsDot style={{ fontSize: '30px' }} /> 24-hour dedicated emergency contact phone
            <br />
            <BsDot style={{ fontSize: '30px' }} /> Conference room with access to internet
            <br />
            <BsDot style={{ fontSize: '30px' }} /> Fully-equipped examination rooms
            <br />
            <BsDot style={{ fontSize: '30px' }} /> Climate controlled and secured drug storage with limited key access
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            Climate controlled and secure on-site records storage
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            Dedicated coordinator offices
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            Infusion Suite
          </p>
        </div>
        <div className='row'>
          <h4 className='common_color'>Diagnostic/Clinic Equipment:</h4>
          <br />
          <br />
          <p>
            <BsDot style={{ fontSize: '30px' }} />
            State of the art CLIA waived laboratory
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            On site emergency equipment including AED, oxygen, crash cart
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            medications (IV fluids, steroids, epinephrine, ASA, inhalers)
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            Ultra So-Lo freezer (-20° and -80°)
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            Locked refrigerator and freezer
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            Data log tag 24/7 temperature monitoring system
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            IV/Infusion Capabilities
            <br />
            <BsDot style={{ fontSize: '30px' }} />
            Centrifuge & Refrigerated Centrifuge Incubator
            <br />
          </p>
        </div>

        <div className='row'>
          <h4 className='common_color'>
            Trialfinity is affiliated with a local diagnostic center with the following capabilities:
          </h4>
          <br />
          <br />
          <p>
            <BsDot style={{ fontSize: '30px' }} /> Ultrasound
            <br />
            <BsDot style={{ fontSize: '30px' }} /> Echocardiogram
            <br />
            <BsDot style={{ fontSize: '30px' }} /> Endoscopy
            <br />
            <BsDot style={{ fontSize: '30px' }} /> Ultra-fast CT
            <br />
            <BsDot style={{ fontSize: '30px' }} /> MRI
            <br />
            <BsDot style={{ fontSize: '30px' }} /> Pulmonary Function Testing
            <br />
            <BsDot style={{ fontSize: '30px' }} /> PSG
            <br />
            <BsDot style={{ fontSize: '30px' }} /> Liquid Nitrogen Storage
            <br />
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
