import React from 'react';
import './Style.css';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import SubHeader from './Components/SubHeader';
import Header from './Components/Header';
import SubFooter from './Components/SubFooter';
import FooterOne from './Components/FooterOne';
import Home from './Components/Home/Home';
import AboutUs from './Components/AboutUs';
import TherapeuticAreas from './Components/TherapeuticAreas';
import OutpatientClinicalTrial from './Components/OutpatientClinicalTrial';
import Contact_us from './Components/Contact_us';
import Patients from './Components/Patients';
import Sponsor from './Components/Sponsor';
import Physician from './Components/Physician';

const App = () => {
  return (
    <>
      <Router>
        <SubHeader />
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about-us' element={<AboutUs />} />
          <Route path='patients' element={<Patients />} />
          <Route path='sponsor' element={<Sponsor />} />
          <Route path='contact-us' element={<Contact_us />} />
          <Route path='i-am-a-physician' element={<Physician />} />
          <Route path='therapeutic-areas' element={<TherapeuticAreas />} />
          <Route path='outpatient-clinical-trial' element={<OutpatientClinicalTrial />} />
        </Routes>
        <FooterOne />
        <SubFooter />
      </Router>
    </>
  );
};

export default App;
