import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa6';
import { IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { FaFacebookF } from 'react-icons/fa6';
import { FaLinkedinIn } from 'react-icons/fa6';
import { FaInstagram } from 'react-icons/fa';

const FooterOne = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const backToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    const button = document.querySelector('.back-to-top');
    button.classList.add('animated-arrow');

    setTimeout(() => {
      button.classList.remove('animated-arrow');
    }, 1000);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div className='container-fluid p-5' style={{ background: '#0c7bc0' }}>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-3'>
            <div className='row'>
              <img src='./images/foot-acrp-logo.png' alt='' style={{ width: '38%' }} />
            </div>
            <br />
            <div className='row'>
              <img src='./images/foot-scrs-logo.png' alt='' style={{ width: '57%' }} />
            </div>
          </div>
          <div className='col-md-5'>
            <h6 style={{ color: '#44FF5E', marginLeft: '13px' }}>Useful Links</h6>
            <ul>
              <li style={{ color: '#fff' }}>
                <Link style={{ color: '#fff', textDecoration: 'none' }} to='/outpatient-clinical-trial'>
                  OutPatient Clinical trial
                </Link>
              </li>
            </ul>
            <ul>
              <li style={{ color: '#fff' }}>
                <Link style={{ color: '#fff', textDecoration: 'none' }} to='/therapeutic-areas'>
                  Therapeutic Areas
                </Link>
              </li>
            </ul>
            <ul>
              <li style={{ color: '#fff' }}>
                <Link style={{ color: '#fff', textDecoration: 'none' }} to={'/about-us'}>
                  About Us
                </Link>
              </li>
            </ul>
            <ul>
              <li style={{ color: '#fff' }}>
                <Link style={{ color: '#fff', textDecoration: 'none' }} to='/contact-us'>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-md-3'>
            <div className='row'>
              <img src='./images/Trailfinity-logo.png' alt='' style={{ width: '50%' }} />
            </div>
            <div className='row pt-4'>
              <div>
                <span style={{ background: '#1B40CB', padding: '0px 6px 6px 6px', marginRight: '20px' }}>
                  <a href='https://www.facebook.com/profile.php?id=100093549819204'>
                    <FaFacebookF style={{ color: 'white' }} />
                  </a>
                </span>
                <span style={{ background: '#217BE0', padding: '0px 6px 6px 6px', marginRight: '20px' }}>
                  <a href='https://www.linkedin.com/in/trialfinity-clinical-research-444a1627a/'>
                    <FaLinkedinIn style={{ color: 'white' }} />
                  </a>
                </span>
                <span style={{ background: '#D62976', padding: '0px 6px 6px 6px', marginRight: '20px' }}>
                  <a href='https://www.instagram.com/trialfinity'>
                    <FaInstagram style={{ color: 'white' }} />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <a href='https://wa.me/16094503535'>
          <FaWhatsapp className='whatsapp_icon' />
        </a>
        {showBackToTop && (
          <button onClick={backToTop} className='btn btn_scrollTo-top btn-lg-square back-to-top'>
            <IoIosArrowUp className='animated-arrow' />
          </button>
        )}
      </div>
    </>
  );
};

export default FooterOne;
