import React from 'react';

const Physician = () => {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <img src='./images/Join-Us-Today_Physician-2.jpg' alt='' />
        </div>
        <br />
        <div className='row'>
          <h2 style={{ color: '#0C7BC0', fontSize: '34px', fontWeight: '600' }}>I am a Physician</h2>
        </div>
        <br />
        <div className='row'>
          <p>
            We believe in collaboration for the advancement of science.
            <br />
            <br /> With over 20 years of experience in clinical research and having expertise in all the phases of
            clinical trials, we work to redefine and improve clinical research outcomes. We understand your commitment
            and are proud of your contribution that you make to improve patient lives.
            <br />
            <br /> At times, conducting clinical trial might be time consuming and arduous tasks as it requires
            dedicated team who can help you to navigate through every step of the clinical trial process. We are here to
            bolster our relationship by providing our expertise in conducting clinical trials and guide you in
            identifying potential patients who could benefit with trial participation.
            <br />
            <br /> Let's bring right drug to right patient. <br />
            <br />
            To learn more about how to become professional partner, Contact us today
            <br />
            <br />
          </p>
        </div>
      </div>
    </>
  );
};

export default Physician;
