import React from 'react';

const SubFooter = () => {
  return (
    <>
      <div className='container-fluid' style={{ background: '#E7E7E7' }}>
        <div className='row text-center pt-2'>
          <p style={{ fontSize: '12px' }}>Copyright &#169; 2023-24. All rights reserved.</p>
        </div>
      </div>
    </>
  );
};

export default SubFooter;
