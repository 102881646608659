import React, { useEffect, useState } from 'react';
import { FaHospital } from 'react-icons/fa6';
import { FaPhoneVolume } from 'react-icons/fa6';
import { CiMail } from 'react-icons/ci';
import { LuMicroscope } from 'react-icons/lu';
import axios from 'axios';
import { BiLoader } from 'react-icons/bi';

const Contact_us = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: '',
  });
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setIsLoading(true);

    var data = JSON.stringify(formData);

    var config = {
      method: 'post',
      url: 'https://api.trialfinity.com/api/product/getbill',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          message: '',
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: '' });
  };
  const validateForm = (data) => {
    const errors = {};

    if (!data.firstName.trim()) {
      errors.firstName = 'Please enter your first name';
    }
    if (!data.lastName.trim()) {
      errors.lastName = 'Please enter your last name';
    }
    if (!data.phone.trim()) {
      errors.phone = 'Please enter your phone number';
    }
    if (!data.email.trim()) {
      errors.email = 'Please enter your email';
    } else if (!isValidEmail(data.email)) {
      errors.email = 'Please enter a valid email address';
    }
    if (!data.message.trim()) {
      errors.message = 'Please enter your message';
    }

    return errors;
  };
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <h2 className='reachUs_style'>Reach Us</h2>
            <div className='row'>
              <div className='col-md-6'>
                <div className='row content_center_style'>
                  <div className='col-sm-3 col-12'>
                    <FaHospital className='content_icon' style={{ color: '#0c7bc0', fontSize: '35px' }} />
                  </div>
                  <div className='col-md-9'>
                    <div className='row'>
                      <h3 style={{ fontSize: '20px', fontWeight: '800', color: '#0c7bc0' }}>
                        <span className='content_center_style'>Address</span>
                      </h3>
                    </div>
                    <div className='row content_center_style address_content_differ'>
                      1245 Whitehorse Mercerville Road, Suite 410-411, Hamilton, New Jersey-08619
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row content_center_style'>
                  <div className='col-sm-3 col-12'>
                    <FaPhoneVolume className='content_icon' style={{ color: '#0c7bc0', fontSize: '35px' }} />
                  </div>
                  <div className='col-md-9'>
                    <div className='row'>
                      <h3 style={{ fontSize: '20px', fontWeight: '800', color: '#0c7bc0' }}>
                        <span className='content_center_style'>Phone</span>
                      </h3>
                    </div>
                    <div className='content_center_style'>+1 609 450 3535</div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />

              <div className='col-md-6 content_height'>
                <div className='row content_center_style'>
                  <div className='col-sm-3 col-12'>
                    <CiMail className='content_icon' style={{ color: '#0c7bc0', fontSize: '35px' }} />
                  </div>
                  <div className='col-md-9'>
                    <div className='row'>
                      <h3 style={{ fontSize: '20px', fontWeight: '800', color: '#0c7bc0' }}>
                        <span className='content_center_style'>Mail Address</span>
                      </h3>
                    </div>
                    <div className='content_center_style'>Info.trialfinity@gmail.com</div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 content_height'>
                <div className='row content_center_style'>
                  <div className='col-sm-3 col-12'>
                    <LuMicroscope className='content_icon' style={{ color: '#0c7bc0', fontSize: '35px' }} />
                  </div>
                  <div className='col-md-9'>
                    <div className='row'>
                      <h3 style={{ fontSize: '20px', fontWeight: '800', color: '#0c7bc0' }}>
                        <span className='content_center_style'>Working Hours</span>
                      </h3>
                    </div>
                    <div className='content_center_style'>Mon to Fri : 8am to 5pm EST</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-6 pt-5'>
            <form onSubmit={handleSubmit}>
              <input
                type='text'
                name='firstName'
                placeholder='FirstName*'
                value={formData.firstName}
                onChange={handleChange}
                style={{ width: '100%', padding: '6px' }}
              />
              {formErrors.firstName && <div className='error'>{formErrors.firstName}</div>}
              <br />
              <br />
              <input
                type='text'
                name='lastName'
                placeholder='LastName*'
                value={formData.lastName}
                onChange={handleChange}
                style={{ width: '100%', padding: '6px' }}
              />
              {formErrors.lastName && <div className='error'>{formErrors.lastName}</div>}
              <br />
              <br />
              <input
                type='text'
                name='phone'
                placeholder='Phone No*'
                value={formData.phone}
                onChange={handleChange}
                style={{ width: '100%', padding: '6px' }}
              />
              {formErrors.phone && <div className='error'>{formErrors.phone}</div>}
              <br />
              <br />
              <input
                type='text'
                name='email'
                placeholder='Email*'
                value={formData.email}
                onChange={handleChange}
                style={{ width: '100%', padding: '6px' }}
              />
              {formErrors.email && <div className='error'>{formErrors.email}</div>}
              <br />
              <br />
              <textarea
                placeholder='Message*'
                name='message'
                value={formData.message}
                onChange={handleChange}
                cols='40'
                rows='6'
                style={{ width: '100%', padding: '6px' }}
              />
              {formErrors.message && <div className='error'>{formErrors.message}</div>}
              <br />
              <br />
              <button type='submit' className='submit_btn'>
                {isLoading ? (
                  <div className='loading'>
                    <BiLoader className='loader' />
                  </div>
                ) : (
                  'Submit'
                )}
              </button>
              {/* <input className='submit_btn' type='submit' value='Submit' /> */}
            </form>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='container-fluid'>
        <div className='row contact_us_bg_img'>
          <div className='col-md-6'>
            <iframe
              className='position-relative w-100 h-100'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3032.150249458378!2d-74.69172934916194!3d40.21051504938229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c1592c3a6aaaab%3A0x7355b506f3863731!2s1245%20Whitehorse%20Mercerville%20Rd%20Suite%20410-411%2C%20Mercerville%2C%20NJ%2008619%2C%20USA!5e0!3m2!1sen!2sus!4v1649496553809!5m2!1sen!2sus'
              frameborder='0'
              style={{ border: '1px solid black', margin: '-7em 0em 0em 0em' }}
              allowfullscreen=''
              aria-hidden='false'
              tabindex='0'
            ></iframe>
          </div>
          <div className='col-md-6'></div>
        </div>
      </div>
    </>
  );
};

export default Contact_us;
