import React from 'react';

const Sponsor = () => {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <img src='./images/Join-US-Today_Sponsor.jpg' alt='' />
        </div>
        <div className='row'>
          <h2 style={{ color: '#0C7BC0', fontSize: '34px', fontWeight: '600' }}>Sponsor</h2>
        </div>
        <div className='row'>
          <h2 style={{ fontSize: '20px' }}>Improving Trial Outcomes with Patient Centric Approach</h2>
        </div>
        <br />
        <div className='row'>
          <p>
            <strong>Research Team</strong>
            <br />
            With a dedicated research team with over 20 years of clinical trial experience, we have a proven track
            record of delivering quality data with patient-centric approach. Our teams’ experience in regulatory,
            contracting, recruiting, budgeting, quality management, risk management, compliance with protocol and more
            gives assurances to our stakeholders to deliver results with the highest quality.
            <br />
            <br />
          </p>
          <p>
            <strong>Population</strong>
            <br />
            We believe a diverse population helps to understand Investigational or Novel medicine better. With a vast
            pool of population that is available, we help our sponsor to recruit subjects who can provide quality data
            and improve trial outcomes.We have a dedicated team who help to recruit, engage and develop a long-lasting
            relationship with trial investigators and subjects.
            <br />
            <br />
          </p>
          <p>
            <strong>Timelines</strong>
            <br />
            We understand drug development and the time it takes to make it available to a larger population. Our teams’
            focus to deliver results within the timelines with a quality centric approach helps our sponsors to achieve
            critical milestones which are crucial to taste the flavor of success.
            <br />
            <br />
          </p>
          <p>
            <strong>Quality</strong>
            <br />
            Drug development relies on the data thus is Quality is at the core of what we do. Our special emphasis on
            quality helps us to produce and deliver data of highest quality and reliability.
            <br />
            <br />
          </p>
        </div>
      </div>
    </>
  );
};

export default Sponsor;
